@import url("https://fonts.googleapis.com/css?family=Sen&display=swap");

* {
	box-sizing: border-box;
}

body {
	width: 100vw;
	height: 100vh;
	margin: 0;
	overflow-x: hidden;
	font-family: "Sen", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: radial-gradient(ellipse, #444, #111);
}

#root {
	width: 100%;
	height: 100%;
}




